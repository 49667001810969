<template>
  <div>
    <el-card class="intanle">
      <span class="h1">スライド管理リスト</span>
    </el-card>
    <el-card class="ft-14">
      <el-row>
        <el-col>
          <p>
            <span style="float: left">
              <el-button @click="toinsert" type="primary" icon="el-icon-plus" style="background-color: #2a4b9b; border: none">新規</el-button>
              <!-- 
                            <el-button type="primary" icon="el-icon-delete" @click="handleDelete1()" style="background-color:rgb(215 163 16);
                  border: none;
                  margin-left: 15px;
                ">削除</el-button> -->
            </span>
          </p>
        </el-col>
      </el-row>
    </el-card>
    <!-- リストカードエリア -->
    <el-card>
      <el-table
        max-height="550px"
        @sort-change="sortChange"
        ref="multipleTable"
        v-loading="loading"
        :data="slideList"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <!-- <el-table-column label="产品ID" prop="productId">
                    <template slot-scope="scope">
                        <el-button @click="openDetails(scope.row)" type="text" size="small"
                            style="color: #409EFF; text-decoration: underline">{{ scope.row.productId }}</el-button>
                    </template>
                </el-table-column> -->
        <el-table-column label="サムネイル" prop="productName1">
          <template slot-scope="scope">
            <el-image
            style="width: 100px; height: 100px"
            :src="$url.urlcqs + scope.row.fileId"
            fit="cover"></el-image>
            <!--  <el-button @click="openDetails(scope.row)" type="text" size="small" style="color: #409EFF; text-decoration: underline">{{ scope.row.productName1 }} </el-button>
            <el-button @click="openDetails(scope.row)" type="text" size="small" style="color: #409EFF; text-decoration: underline">{{ scope.row.estimateActivityName }} </el-button> -->
          </template>
        </el-table-column>
        <el-table-column label="タイプ" prop="weight">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">ホーム</span>
            <span v-if="scope.row.type == 1">ショップホーム</span>
            <span v-if="scope.row.type == 2">評価ホーム</span>
          </template>
        </el-table-column>
        <el-table-column label="適用範囲" prop="isMobile">
          <template slot-scope="scope">
            <span v-if="scope.row.isMobile == 0">PC</span>
            <span v-if="scope.row.isMobile == 1">モバイル</span>
          </template>
        </el-table-column>

        <el-table-column label="重み" prop="weight"></el-table-column>
        <el-table-column label="作成日時" prop="createTime" sortable="custom"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleupdate(scope.$index, scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">編集</el-button>
            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="small" class="el-icon-delete" style="color: #da0909">削除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- ページング -->
      <el-pagination v-if="total > 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      <div style="clear:both;"></div>
    </el-card>
  </div>
</template>

<script>
import slideService from '@/api/slide/slideService'
export default {
  data() {
    return {
      slideService: new slideService(), //インターフェース定義
      currentPage: 1, //ページ数
      pageSize: 10, //1ページあたりの件数
      total: 0, //合計数
      slideList: [], //スライド列表数据
      loading: true, //ローディング
      slideId: '', //スライドid
      sortBy: '',  //ソート
      multipleSelection: [] //複数選択されたデータ
    }
  },
  created() {
    this.GetSlideList()
  },
  methods: {
    // チェックボックスの値を取得
    handleSelectionChange(val) {
      if (val.length != 0) {
        this.multipleSelection = val
        this.slideId = val[0].slideId
      }
    },
    /* ページサイズ変更イベントのリスナー */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.GetSlideList()
    },
    /* ページ番号変更イベントのリスナー */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.GetSlideList()
    },
    //リスト取得
    async GetSlideList() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      this.slideService.list(date).then(res => {
        if (res.data.success == 'true') {
          this.slideList = res.data.result
          this.loading = false
          this.total = Number(res.data.maxRecords)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //照会
    async searchTaskList() {
      this.currentPage = 1
      this.GetSlideList()
    },
    //詳細ページ遷移
    openDetails(row) {
      this.$router.push({
        name: 'GetSlideDetails',
        query: {
          slideId: row.slideId,
          type: 'details'
        }
      })
    },
    //変更ページ移動
    handleupdate(index, row) {
      // console.log(row)
      this.$router.push({
        name: 'GetSlideDetails',
        query: {
          slideId: row.slideId,
          type: 'update',
          currentPage: this.currentPage
        }
      })
    },
    //新規追加ページ遷移
    toinsert() {
      this.$router.push({
        name: 'GetSlideDetails',
        query: {
          type: 'insert'
        }
      })
    },
    //リセット
    resetList() {
      this.name = ''
      this.GetSlideList()
    },
    //1行削除
    handleDelete(row) {
      var data = { 
        slideId: row.slideId,
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole')
      }
      this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(
          async () => {
          const { data: res } = await this.$http.post('m_deleteSlide', 
            data
          )
          this.loading = false
          // console.log(res);
          if (res.success == 'true') {
            this.$message({
              type: 'success',
              message: '削除成功!'
            })
            this.GetSlideList()
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
        }
      )
        .catch(err => {
          // this.$message({
          //     type: "error",
          //     message: err
          // })
          this.GetSlideList()
        })
    },
    // 複数選択削除
    async handleDelete1() {
      var data1 = this.multipleSelection
      var data2 = []
      if (this.multipleSelection == undefined) {
        this.$confirm('データを選択してください', '表示', {
          type: 'warning'
        })
      } else {
        this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            for (var i = 0; i < data1.length; i++) {
              data2.push(data1[i].slideId)
            }
            this.$message({
              type: 'success',
              message: '削除成功!'
            })
            this.GetSlideList()
          })
          .catch(err => {})
      }
    },
    //リストのソート
    sortChange(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'createTime') {
          this.sortBy = 's.createTime ASC'
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'createTime') {
          this.sortBy = 's.createTime DESC'
        }
      }
      this.GetSlideList()
    }
  }
}
</script>

<style lang="less" scoped>
.cl-hui {
  color: #b2b2b2 !important;
}

.cl-bl {
  color: #606262;
}
</style>
