import request from "@/utils/service.js"
export default class slideService {
  
  list(data) {
    return request({
      url: 'm_getSlideList',
      method: 'post',
      data: data
    })
  }
  detail(data) {
    return request({
      url: 'm_getSlide',
      method: 'post',
      data: data
    })
  }
  add(data) {
    return request({
      url: 'm_addSlide',
      method: 'post',
      data: data
    })
  }
  update(data) {
    return request({
      url: 'm_updateSlide',
      method: 'post',
      data: data
    })
  }
  delete(data) {
    return request({
      url: 'm_deleteSlide',
      method: 'post',
      data: data
    })
  }
}
